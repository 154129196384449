import React, { Component } from 'react';
import { Container, Row, Col, Navbar, Button, Form, Alert } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import axios from "axios";
import RecentUploadsCarousel from './recent';
import PhotoGallery from './everything';
class Homepage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            files: [],
            isUploading: false,
            uploadSuccess: false,
            uploadError: false,
	    name:""
        };
        document.title = 'Amber and Drews Wedding Memories'; 
    }

    handleFileChange = (e) => {
        this.setState({
            files: e.target.files,
            uploadSuccess: false,  
            uploadError: false    
        });
    };

    handleSubmit = async (e) => {
        e.preventDefault();
        const formData = new FormData();
    formData.append("uploader_name", this.state.name);

        // Append each file to FormData
        Array.from(this.state.files).forEach(file => {
            formData.append("files", file);
        });

        this.setState({ isUploading: true });

        try {
            const response = await axios.post("/api/upload/", formData, {
                headers: {
                    "Content-Type": "multipart/form-data",
                },
            });
            console.log(response.data);

            // After successful upload
            this.setState({
                isUploading: false,
                uploadSuccess: true,
                files: [] 
            });
            document.getElementById('formFile').value = null;
        } catch (error) {
            console.error("Error uploading files:", error);

            this.setState({
                isUploading: false,
                uploadError: true
            });
        }
    };
handleName = (e) => {
this.setState({name: e.target.value})
}

    render() {
        const { isUploading, uploadSuccess, uploadError } = this.state;

        return (
            <>


                <div  style={{ backgroundColor: 'rgba(20, 20, 20, 1)',overflow:'scroll', textAlign:'center' }}  >
                    <Container fluid className="h-100 d-flex flex-column justify-content-center " style={{marginTop:100}}>
                        <Row>
                            <Col>
                                <h1>
                                    <img src='./benson-crest.png' height={350} alt="Hero Logo" />
                                </h1>
                            </Col>
                            <Col>
                                <div className="infoCard" style={{ height: 400,color:'white' }} data-aos="fade-left" data-aos-duration="1000">
                                    <h3 style={{ paddingBottom: 10 }}>Share Your Pictures With The Newlyweds</h3>
                                    <Container className="mt-5">
                                        {uploadSuccess && <Alert variant="success">Photos uploaded successfully!</Alert>}
                                        {uploadError && <Alert variant="danger">There was an error uploading your photos. Please try again.</Alert>}
                                        <Form onSubmit={this.handleSubmit}>
                                            <Form.Group controlId="formFile" className="mb-3">
                                                <Form.Label>Upload your photos and videos</Form.Label>
                                                          <Form.Control
            type="text"
            placeholder="Optional: Enter name"
            value={this.state.name}
            onChange={this.handleName}
style={{marginBottom:8}}
          />
                                                <Form.Control type="file" multiple onChange={this.handleFileChange} /> {/* Allow multiple file selection */}
                                            </Form.Group>
                                            <Button variant="dark" type="submit" disabled={isUploading}>
                                                {isUploading ? 'Uploading...' : 'Upload'}
                                            </Button>
                                        </Form>
                                    </Container>
                                </div>
                            </Col>
                        </Row>
                        {/* <RecentUploadsCarousel/> */}
                        <PhotoGallery/>
                    </Container>
                </div>
            </>
        );
    }
}

export default Homepage;
