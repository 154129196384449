import React, { Component } from 'react';
import { Carousel, Container, Row, Col } from 'react-bootstrap';
import axios from 'axios';

class RecentUploadsCarousel extends Component {
    state = {
        recentPhotos: []
    };

    componentDidMount() {
        this.fetchRecentPhotos();
    }

    fetchRecentPhotos = async () => {
        try {
            const response = await axios.get('http://10.0.0.85:8000/recent/'); 
            this.setState({ recentPhotos: response.data.recent_files });
        } catch (error) {
            console.error("Error fetching recent photos:", error);
        }
    };

    render() {
        const { recentPhotos } = this.state;

        return (
            <Container className="mt-5">
                <h4 style={{color:'white',textAlign:'center'}}> Most Recent Wedding Uploads</h4>
                <Carousel>
                    {recentPhotos.map((photo, index) => (
                        <Carousel.Item key={index}>
                        <img
                            className="d-block w-100"
                            src={`https://ambers-wedding.s3.amazonaws.com/${photo.filename}`} 
                            alt="Uploaded photo"
                            style={{ maxHeight: '400px', objectFit: 'cover' }}
                        />

                            <Carousel.Caption>

                                <p> From {photo.uploader_name} </p>

                            </Carousel.Caption>
                        </Carousel.Item>
                    ))}
                </Carousel>
            </Container>
        );
    }
}

export default RecentUploadsCarousel;
