import React, { Component } from 'react';
import { Container, Row, Col, Card } from 'react-bootstrap';
import axios from 'axios';

class PhotoGallery extends Component {
    state = {
        photos: [],
        continuationToken: null,  // To track the continuation token for pagination
        loading: false,           // To track loading state
        hasMore: true             // To track if more photos are available
    };

    componentDidMount() {
        this.fetchPhotos();
        window.addEventListener('scroll', this.handleScroll);  // Add scroll event listener
    }

    componentWillUnmount() {
        window.removeEventListener('scroll', this.handleScroll);  // Remove scroll event listener on unmount
    }

    fetchPhotos = async () => {
        if (this.state.loading || !this.state.hasMore) return;  // Avoid duplicate requests
        this.setState({ loading: true });
    
        try {
            const { continuationToken } = this.state;
            const response = await axios.get('/api/photos', {
                params: {
                    limit: 20,  // Load 20 images at a time
                    continuation_token: continuationToken || ''
                }
            });
    
            const newPhotos = response.data.photos;
            const nextContinuationToken = response.data.next_continuation_token;
    
            this.setState((prevState) => ({
                // Append older photos as user scrolls
                photos: continuationToken ? [...prevState.photos, ...newPhotos] : newPhotos,
                continuationToken: nextContinuationToken,
                loading: false,
                hasMore: !!nextContinuationToken  // Set to false if there are no more photos
            }));
        } catch (error) {
            console.error("Error fetching photos:", error);
            this.setState({ loading: false });
        }
    };

    // Handle infinite scroll
    handleScroll = () => {
        if ((window.innerHeight + window.scrollY) >= document.body.offsetHeight - 500 && this.state.hasMore) {
            this.fetchPhotos();
        }
    };

    render() {
        const { photos } = this.state;

        return (
            <Container className="mt-5">
                <h1 style={{color:'white'}}>Photo Gallery</h1>
                <Row>
                    {photos.map((photo, index) => (
                        <Col md={3} key={index} className="mb-4">
                            <Card>
                                <Card.Img variant="top" src={`https://ambers-wedding.s3.amazonaws.com/${photo.filename}`} alt="Uploaded photo" />
                                <Card.Body>
                                    <Card.Text>
                                        From: {photo.uploader_name}
                                    </Card.Text>
                                </Card.Body>
                            </Card>
                        </Col>
                    ))}
                </Row>
                {this.state.loading && <p style={{color:'white'}}>Loading more photos...</p>}
            </Container>
        );
    }
}

export default PhotoGallery;
