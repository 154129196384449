import React, {Component} from "react";
// import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
// import { Provider } from "react-redux";
import 'bootstrap/dist/css/bootstrap.min.css';
// import Cookies from "js-cookie";
// import { RequireToken, RequireAdminToken } from "./Auth";
import Homepage from "./components/home";

class App extends Component {
  
  render() {
    return (
          <div className="App">
              <Homepage />

          </div>

    );
  }
}

export default App;
